import React from "react"
import { graphql, Link } from "gatsby"
import { FormattedMessage } from "react-intl"

import useLangs from "../../hooks/useLangs"
import Layout from "../../components/layout/index"
import SEO from "../../components/seo"
import ProductsGrid from "../../components/productsGrid/index"
import StyledSectionBox from "../../components/sectionBox/index"
import { StyledContainer } from "../../components/containers/index"

const PolishedAndCoatedProductsPageEn = ({
  location,
  data: { site, background, images, products },
}) => {
  const polishedCoatedProducts = products.edges[0].node.data.products.filter(
    prod => {
      return prod.product_category === "Polished and Coated Products"
    }
  )

  const { localLink } = useLangs(location.pathname, site.siteMetadata.languages)
  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="en-US"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="products"
      />
      <StyledContainer>
        <h3>
          <Link
            to={`${localLink}products`}
            style={{ textDecoration: "none", color: "#1F1B1B" }}
          >
            <FormattedMessage id="products" />
          </Link>{" "}
          / <FormattedMessage id="polishedCoatedProducts" />
        </h3>
      </StyledContainer>
      <StyledContainer>
        <ProductsGrid products={polishedCoatedProducts} />
      </StyledContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        languages {
          langs
          defaultLangKey
        }
      }
    }
    background: file(relativePath: { eq: "products/products-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    products: allPrismicProducts(filter: { lang: { eq: "en-us" } }) {
      edges {
        node {
          id
          data {
            products {
              product_category
              product_description
              product_image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PolishedAndCoatedProductsPageEn
